<template>
    <header class="container px-4 mx-auto flex pt-7 items-center justify-between flex-wrap">
<!--        class="skip-to-content-link"-->
        <button tabindex="0"
                class="bg-white text-black -translate-x-2/4	absolute left-1/2 -translate-y-64 focus:translate-y-1"
                @click.prevent="skipToMainContent"
                :aria-label="$t('wcag.goToMain')"
        >
            {{ $t('wcag.goToMain') }}
        </button>
        <LogoPrimary />
        <nav class="py-1 md:w-auto">
            <HeaderLinks />
        </nav>
    </header>
    <div class="flex sm:hidden items-center justify-around px-4 flex-wrap w-full fixed bottom-0 bg-white nav-shadow z-20">
        <router-link :to="{name: getRouteName('home')}" class="text-center py-3">
            <img src="@/assets/images/search.svg" :alt="$t('footer.searchIconAlt')" class="mx-auto search-icon mobile-menu-icon">
            <span class="text-sm leading-4">{{ $t('pages.search') }}</span>
        </router-link>
        <router-link :to="{name: getRouteName('forTranslators')}" class="py-3">
            <img src="@/assets/images/translate.svg" :alt="$t('footer.messageIconAlt')" class="mx-auto mobile-menu-icon">
            <span class="text-sm leading-4">{{ $t('pages.forTranslators') }}</span>
        </router-link>
        <router-link :to="{name: getRouteName('inquiries')}" class="py-3">
            <img src="@/assets/images/message.svg" :alt="$t('footer.enquiriesIconAlt')" class="mx-auto mobile-menu-icon">
            <span class="text-sm leading-4">{{ $t('pages.enquiries') }}</span>
        </router-link>
        <router-link :to="{name: getRouteName('inquiries')}" class="py-3">
            <img src="@/assets/images/account.svg" :alt="$t('footer.accountIconAlt')" class="mx-auto account-icon mobile-menu-icon">
            <span class="text-sm leading-4">{{ $t('pages.account') }}</span>
        </router-link>
    </div>
    <div class="container px-4 mx-auto flex xl:pt-7 pt-4 justify-end">
        <img class="h-8" :aria-label="$t('homeView.logo_unia')" src="../assets/images/logo_ue.png">
    </div>
</template>

<script setup>
import LogoPrimary from '@/components/atoms/LogoPrimary.vue'
import HeaderLinks from '@/components/HeaderLinks.vue'
import {getRouteName} from "@/router";

const skipToMainContent = () => {
    document.querySelector('main').setAttribute('tabindex','-1');
    document.querySelector('main').focus();
    document.querySelector('main').scrollIntoView();
}
</script>

<style lang="scss" scoped>
.skip-to-content-link:focus {
    transform: translateY(10%);
    background-color: yellow;
    color: black;
    z-index: 2;
}

.skip-to-content-link {
    background: #f1d600;
    border: none;
    text-decoration: none;
    color: black;
    border-radius: 60px;
    height: auto;
    left: 50%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    position: absolute;
    transform: translateY(-450%);
    transition: transform 0.3s;
    font-size: 13px;
}

.nav-shadow {
    box-shadow: 0px 0 3px rgb(0 0 0 / 30%);
}
</style>
