import {defineStore} from 'pinia'
import axios from "axios";
import ApiHelper from "@/helpers/apiHelper";

export const useFrequentlySearchedTranslatorsStore = defineStore({
    id: 'frequentlySearchedTranslators',
    state: () => {
        return {
            totalCount: 0,
            pageSize: 0,
            totalPageCount: 0,
            currentTotalCount: 0,
            pageToLoad: 1,
            isLoadingMoreFrequentlySearchedTranslators: false,
            frequentlySearchedTranslators: [],
            availableMoreFrequentlyTranslators: false
        }
    },
    actions: {
        fetchFrequentlySearchedTranslators(loadMore = false) {
            if (this.frequentlySearchedTranslators.length < 1 || loadMore) {
                if (loadMore)
                    this.isLoadingMoreFrequentlySearchedTranslators = true;
                axios.get(ApiHelper.getFrequentlySearchedTranslatorsUrl(),
                    {
                        params: {
                            page: this.pageToLoad,
                        }
                    }
                ).then(result => {
                    this.frequentlySearchedTranslators = result.data.result;
                    this.totalCount = result.data.totalCount;
                    this.pageSize = result.data.pageSize;
                    this.totalPageCount = result.data.totalPageCount;
                    this.currentTotalCount = this.frequentlySearchedTranslators.length;
                    this.availableMoreFrequentlyTranslators = this.currentTotalCount < this.totalCount;
                    if (this.currentTotalCount < this.totalCount)
                        this.pageToLoad = (this.currentTotalCount / this.pageSize) + 1;
                    this.isLoadingMoreFrequentlySearchedTranslators = false;
                }).catch(() => {
                    this.isLoadingMoreFrequentlySearchedTranslators = false;
                });
            }
        },
    },
});
