import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b9d99c30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "autocomplete-container w-full relative" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "placeholder"]
const _hoisted_4 = ["id", "placeholder"]
const _hoisted_5 = ["id", "aria-label"]
const _hoisted_6 = ["onKeypress", "onClick"]
const _hoisted_7 = ["onKeypress", "onClick"]
const _hoisted_8 = ["onKeypress", "onClick"]
const _hoisted_9 = {
  key: 2,
  class: "hover:bg-gray-100 p-2.5"
}
const _hoisted_10 = {
  key: 1,
  class: "hover:bg-gray-100 p-2.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["autocomplete w-full md:pr-6 md:pb-0", {
            'pb-6': !$props.noComponentMargin
         }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.hiddenLabel)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("label", {
              class: _normalizeClass(["hidden text-sm font-medium text-gray-900 dark:text-gray-500", {'flex items-center gap-2': $props.horizontalLabel}]),
              for: $props.id
            }, _toDisplayString($props.label), 11, _hoisted_2),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: $props.id,
              class: _normalizeClass(["bg-gray-100 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-4", $props.customClass]),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchTerm) = $event)),
              placeholder: $props.placeholderText,
              onKeydown: [
                _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => ($setup.search && $setup.search(...args)), ["enter"])),
                _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => ($setup.closeAutocomplete && $setup.closeAutocomplete(...args)), ["esc"]))
              ],
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.search(false)), ["prevent"])),
              onInput: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($setup.showResult = true), ["prevent"])),
              onKeyup: _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => ($setup.clearInput && $setup.clearInput(...args)), ["delete"])),
              autocomplete: "off"
            }, null, 42, _hoisted_3), [
              [_vModelText, $setup.searchTerm]
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("label", {
            key: 1,
            class: _normalizeClass(["block text-sm font-medium text-gray-900 dark:text-gray-500", {'flex items-center gap-2': $props.horizontalLabel}])
          }, [
            _createTextVNode(_toDisplayString($props.label) + " ", 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: $props.id,
              class: _normalizeClass(["mt-2 bg-gray-100 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-4", $props.customClass]),
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.searchTerm) = $event)),
              placeholder: $props.placeholderText,
              onKeydown: [
                _cache[7] || (_cache[7] = _withKeys(
//@ts-ignore
(...args) => ($setup.search && $setup.search(...args)), ["enter"])),
                _cache[10] || (_cache[10] = _withKeys(
//@ts-ignore
(...args) => ($setup.closeAutocomplete && $setup.closeAutocomplete(...args)), ["esc"]))
              ],
              onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => ($setup.search(false)), ["prevent"])),
              onInput: _cache[9] || (_cache[9] = _withModifiers(($event: any) => ($setup.showResult = true), ["prevent"])),
              onKeyup: _cache[11] || (_cache[11] = _withKeys(
//@ts-ignore
(...args) => ($setup.clearInput && $setup.clearInput(...args)), ["delete"])),
              autocomplete: "off"
            }, null, 42, _hoisted_4), [
              [_vModelText, $setup.searchTerm]
            ])
          ], 2)),
      ($setup.showResult)
        ? (_openBlock(), _createElementBlock("ul", {
            key: 2,
            id: 'autocomplete_' + $props.id,
            class: "absolute bg-white cursor-pointer z-10 w-full overflow-auto max-h-72",
            onKeyup: _cache[12] || (_cache[12] = _withKeys(
//@ts-ignore
(...args) => ($setup.closeAutocomplete && $setup.closeAutocomplete(...args)), ["esc"])),
            tabindex: "-1",
            "aria-label": _ctx.$t('wcag.autocomplete.list')
          }, [
            (!$setup.loadingAnswer)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  ($setup.searchItems.length && $setup.searchTerm != '')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.searchItems, (item) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: item[$props.itemsValueKey],
                          onKeypress: _withKeys(($event: any) => ($setup.selectItem(item[$props.itemsValueKey], item[$props.itemsNameKey], item)), ["enter"]),
                          onClick: ($event: any) => ($setup.selectItem(item[$props.itemsValueKey], item[$props.itemsNameKey], item)),
                          tabindex: "0",
                          class: "hover:bg-gray-100 p-2.5"
                        }, _toDisplayString(item[$props.itemsNameKey]), 41, _hoisted_6))
                      }), 128))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        ($setup.searchTerm == '' && $setup.selectedValue != '')
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.items, (item) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: item[$props.itemsValueKey],
                                onKeypress: _withKeys(($event: any) => ($setup.selectItem(item[$props.itemsValueKey], item[$props.itemsNameKey], item)), ["enter"]),
                                onClick: ($event: any) => ($setup.selectItem(item[$props.itemsValueKey], item[$props.itemsNameKey], item)),
                                tabindex: "0",
                                class: "hover:bg-gray-100 p-2.5"
                              }, _toDisplayString(item[$props.itemsNameKey]), 41, _hoisted_7))
                            }), 128))
                          : ($setup.selectedValue?.name)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.items, (item) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: item[$props.itemsValueKey],
                                  onKeypress: _withKeys(($event: any) => ($setup.selectItem(item[$props.itemsValueKey], item[$props.itemsNameKey], item)), ["enter"]),
                                  onClick: ($event: any) => ($setup.selectItem(item[$props.itemsValueKey], item[$props.itemsNameKey], item)),
                                  tabindex: "0",
                                  class: "hover:bg-gray-100 p-2.5"
                                }, _toDisplayString(item[$props.itemsNameKey]), 41, _hoisted_8))
                              }), 128))
                            : (_openBlock(), _createElementBlock("li", _hoisted_9, _toDisplayString(_ctx.$t('autocomplete.noResult')), 1))
                      ], 64))
                ], 64))
              : (_openBlock(), _createElementBlock("li", _hoisted_10, _toDisplayString(_ctx.$t('autocomplete.loading')), 1))
          ], 40, _hoisted_5))
        : _createCommentVNode("", true)
    ])
  ], 2))
}