import { defineStore } from 'pinia';
import router, {getRouteName} from "@/router";
import axios from "axios";
import UserService from "@/services/userService";
import ApiHelper from "@/helpers/apiHelper";
import authHelper from "@/helpers/authHelper";
import {removeCookie} from "@/helpers/cookieHelper";
import {clearAllStores} from "@/helpers/piniaHelper";

 interface User {
    userPermissions?: [];
    firstName?: string;
    lastName?: string;
    translatorId?: string;
}

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: {} as User,
        isLoading: false,
        returnUrl: '',
    }),
    actions: {
        getUser() {
            try {
                this.isLoading = true;
                return axios.get(ApiHelper.getUserInfoUrl(), {headers: {'Authorization': authHelper.authHeader()}})
                    .then(response => {
                        this.user = response.data;
                        this.isLoading = false;
                        // console.log('USER',this.user);
                        return Promise.resolve(this.user);
                    }).catch(error => {
                        return Promise.reject(error);
                    })
            } catch (e) {
                this.isLoading = false;
                throw e;
            }
        },

        logout() {
            this.user = {};
            removeCookie('auth-token');
            removeCookie('auth-refresh');

            clearAllStores();
            router.push({name: getRouteName('login')});
        },
    }
});
