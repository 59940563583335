import {defineStore} from 'pinia'
import axios from "axios";
import ApiHelper from "@/helpers/apiHelper";
import i18n from "@/i18n";
import {ref} from "vue";
import router from "@/router";
import {parseUrl, unparseUrl} from "@/helpers/UrlParse";
import {LocationQueryValue} from "vue-router";

export interface Item {
    id: string;
    name: string;
    slug: string;
    lat?: number;
    lng?: number;
}

export type RootState = {
    items: Item[];
};

export const useSearchTranslatorFiltersStore = defineStore({
    id: 'searchTranslatorFilters',
    state: () => {
        const defaultTranslationType = {id: 9999, name: i18n.global.t('searchSection.defaultTranslationType'), slug: 'dowolne'};
        return {
            fetchAllData: false,
            isLoading: false,
            availableLangs: [] ,
            availableTranslationTypes: [],
            availableLocations: [{}],
            filterLang: ref('')
        }
    },
    actions: {
         fetchAvailableLangs(reload = false) {
             if (!reload && this.availableLangs.length)
                 return this.availableLangs;

            return axios.get(ApiHelper.getAvailableLanguagesUrl()
            ).then(result => {
                this.availableLangs = result.data;
            }).catch();
        },
         fetchAvailableTranslationsTypes(reload = false) {
             if (!reload && this.availableTranslationTypes.length > 1)
                 return this.availableTranslationTypes;

            return axios.get(ApiHelper.getAvailableTypesOfTranslationsUrl()
            ).then(result => {
                this.availableTranslationTypes = result.data
            }).catch();
        },
         fetchAvailableLocations(reload = false) {
             if (!reload && this.availableLocations.length > 1)
                 return this.availableLocations;

            return axios.get(ApiHelper.getAvailableLocationsUrl()
            ).then(result => {
                this.availableLocations = result.data;
                const cityName: any = router.currentRoute.value.params.city ?? '';

                const locationForList = this.availableLocations.filter((el: any) => {
                    if(el.slug == cityName)
                        return el;
                })

                if(!Object.keys(locationForList)?.length) {
                    // console.log('locationForList',locationForList)
                    if (cityName != '') {
                        const obj = {
                            id: 9999,
                            name: unparseUrl(cityName),
                            slug: parseUrl(cityName),
                            lat: router.currentRoute.value.query.lat,
                            lng: router.currentRoute.value.query.lng,
                        }
                        this.availableLocations.push(obj);
                    }
                }
            }).catch();
        },
    },
});
