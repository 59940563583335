import {getCookie} from "@/helpers/cookieHelper";
import {Role} from "@/helpers/roleHelper";
import {Store} from "pinia";
import {useAuthStore} from "@/stores/authStore";

class AuthHelper {

    authHeader() {
        return getCookie('auth-token') ? ("Bearer " + getCookie('auth-token')) : '';
    }

    isLogged() {
        return getCookie('auth-token') && getCookie('auth-expiration') ? true : false;
    }

    isTranslator() {
        const authUser: Store<string, any> = useAuthStore();
        return authUser?.user?.userPermissions?.includes(Role.Translator);
    }

    isVipTranslator() {
        const authUser: Store<string, any> = useAuthStore();
        return authUser?.user?.userPermissions?.includes(Role.TranslatorVIP);
    }

    isTranslatorOrVipTranslator() {
        const authUser: Store<string, any> = useAuthStore();
        return authUser?.user?.userPermissions?.includes(Role.TranslatorVIP) || authUser?.user?.userPermissions?.includes(Role.Translator);
    }

    isClient() {
        const authUser: Store<string, any> = useAuthStore();
        return authUser?.user?.userPermissions?.includes(Role.Client);
    }

}

export default new AuthHelper();