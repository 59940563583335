class StoreCache {
    prefix: string;

    constructor(prefix?:string) {
        this.prefix! = prefix ? prefix : 'sworn_';
    }
    createFullKey(key: string)  {
        return this.prefix + key;
    }
    get(key: string) {
        return JSON.parse(localStorage.getItem(this.createFullKey(key)) || '{}');
    }
    set(key: string, value: any) {
        localStorage.setItem(this.createFullKey(key), JSON.stringify(value))
    }
    remove(key: string) {
        localStorage.removeItem(this.createFullKey(key))
    }
    clear() {
        localStorage.clear()
    }
}

export default new StoreCache();