<template>
    <div v-for="(card, index) in cards" :key="index" class="translator-card skeleton-translator-card md:flex bg-white" :class="{ '--alternativeLook': alternativeLook }">
        <div v-if="!alternativeLook" class="translation-card-triangle --light" />
        <div v-if="!alternativeLook" class="translation-card-triangle --dark" />
        <div class="photo md:block hidden"></div>
        <div class="skeleton-translator-card-container">
            <div class="flex translator-card-row md:items-center flex-wrap">
                <div class="photo md:hidden block"></div>
                <h3 class="w-8/12 md:w-full mr-0 md:mr-8"></h3>
            </div>
            <div class="translator-card-row flex-wrap">
                <div class="flex">
                    <p></p>
                </div>
                <div class="flex text-light-secondary items-center">
                    <p></p>
                </div>
            </div>
            <div class="flex flex-col-reverse xl:flex-row">
                <div class="flex flex-wrap translator-card-row">
                    <a></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const cards = 2;
const alternativeLook = false;
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.translator-card {
    position: relative;
    padding: 24px 24px 0 24px;
    &.--alternativeLook {
        padding-left: 0;
        padding-right: 0;
        border-top: 1px solid $dark-primary;
    }
}
.translator-card-row {
    padding-bottom: 24px;
}
.translation-card-triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 0px;
    height: 0px;
    &.--light {
        border-left: 40px solid transparent;
        border-top: 40px solid $grey-200;
    }
    &.--dark {
        border-right: 40px solid transparent;
        border-bottom: 40px solid $grey-300;
    }
}

//skeleton styles
.skeleton-translator-card {
    display: flex;
    background-color: white;
    height: fit-content;
    margin-bottom: 20px;

    &-container {
        width: 80%;

        & h3 {
            background-color: #e4e3e3;
            height: 1.5rem;
            animation: pulse-bg 1s infinite;
        }

        & p {
            width: 100%;
            background-color: #e4e3e3;
            height: 1rem;
            margin-right: 2rem;
            margin-bottom: 1rem;
            animation: pulse-bg 1s infinite;
        }

        & a {
            border: none;
            background-color: #f3f3f3;
            color: white;
            border-radius: 3px;
            width: 200px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            height: 3.125rem;
            animation: pulse-bg 1s infinite;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }

    & div.photo {
        background-color: #e4e3e3;
        height: 65px;
        width: 65px;
        border-radius: 50%;
        animation: pulse-bg 1s infinite;
        margin-right: 1.5rem;
        margin-bottom: 0.5rem;
    }
}


@keyframes pulse-bg {
    0% { background-color: #ddd; }
    50% { background-color: #d0d0d0; }
    100% { background-color: #ddd; }
}
</style>