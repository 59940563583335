import {inject, ref} from "vue";

export default function () {

    const alert: any = inject('alert');

    const showAlert = (title, desc = '',status = 'success') => {
        alert.value.showAlert(
            status,
            desc,
            title,
            {
                iconSize: 35,
                iconType: 'solid',
                position: 'top right',
            }
        );
    }

    return {
        showAlert
    };

}