import {getActivePinia, Pinia, Store} from "pinia";

interface ExtendedPinia extends Pinia {
    _s: Map<string, Store>;
}

export function clearAllStores() {
    const pinia = getActivePinia() as ExtendedPinia;
    pinia?._s.forEach(store => store.$reset());
}

export function clearBasicStores() {
    const pinia = getActivePinia() as ExtendedPinia;
    pinia?._s.forEach(store => {
        if (store.$id != 'auth')
            store.$reset()
    });
}