import storeCache from "@/helpers/storeCache";
import router from "@/router";
import {useSearchTranslatorFiltersStore} from "@/stores/searchTranslatorFilters.store";

export default class FindingTranslatorParamsParser {

    private searchTranslatorFiltersStore = useSearchTranslatorFiltersStore()

    fetchAllParams() {
        this.fetchLangFromRoute();
        this.fetchCityFromRoute();
        this.fetchTranslationTypeFromRoute();
    }

    fetchLangFromRoute() {
        const langSlug = router.currentRoute.value.params.language;
        if (langSlug) {
            const availableLangs = this.searchTranslatorFiltersStore.availableLangs
            availableLangs.filter(item => {
                if (item['slug'] === langSlug) {
                    storeCache.set('searchSelectedLang', {
                        name: item['name'],
                        value: item['id'],
                        item: item
                    });
                }
            });
        } else {
            storeCache.remove('searchSelectedLang');
        }
    }

    fetchTranslationTypeFromRoute() {
        const typeSlug = router.currentRoute.value.params.types;
        if (typeSlug) {
            const availableTypes = this.searchTranslatorFiltersStore.availableTranslationTypes
            availableTypes.filter(item => {
                if (item['slug'] === typeSlug) {
                    storeCache.set('searchSelectedTranslationType', {
                        name: item['name'],
                        value: item['id'],
                        item: item
                    });
                }
            });
        } else {
            storeCache.remove('searchSelectedTranslationType');
        }
    }

    fetchCityFromRoute() {
        const citySlug = router.currentRoute.value.params.city;
        if (citySlug) {
            const availableLocations = this.searchTranslatorFiltersStore.availableLocations
            availableLocations.filter(item => {
                if (item['slug'] === citySlug) {
                    storeCache.set('searchSelectedCity', {
                        name: item['name'],
                        value: item['id'],
                        item: item
                    });
                }
            });
        } else {
            storeCache.remove('searchSelectedCity');
        }
    }
}