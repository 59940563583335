import {RouteRecordRaw} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import DefaultPage from "@/components/DefaultPage.vue";
import UserService from "@/services/userService";

const routeTranslations = {
    translators: 'die-uebersetzer',
    forTranslators: 'fuer-uebersetzer',
    translator: 'der-uebersetzer',
    login: 'anmeldung',
    registration: 'registrierung',
    verify: 'ueberpruefen',
    forgotPassword: 'passwort-vergessen',
    changePassword: 'passwort-aendern',
    logout: 'ausloggen',
    inquiries: 'anfrage',
    orders: 'auftraege',
    invoices: 'rechnungen',
    profileSettings: 'profil-einstellungen',
    accountSettings: 'konto-einstellungen',
    chat: 'chat',
    repertory: 'urkundenrolle',
}

const routePrefix = 'de';
const routePaths = {
    home: '',
    findTranslator: routeTranslations.translators + '/:language/:city?/:types?',
    forTranslators: routeTranslations.forTranslators,
    translatorPage: routeTranslations.translator + '/:nameSlug/:tpNumberSlug',
    login: routeTranslations.login,
    registration: routeTranslations.registration,
    registrationEmailVerification: routeTranslations.registration + '/' + routeTranslations.verify + '/:userEmail',
    registrationTranslatorVerification: routeTranslations.registration + '/' + routeTranslations.translator + '/' + routeTranslations.verify + '/:tpNumber?',
    forgotPassword: routeTranslations.forgotPassword,
    changePassword: routeTranslations.changePassword + '/:userEmail',
    logout: routeTranslations.logout,
    inquiries: routeTranslations.inquiries,
    orders: routeTranslations.orders,
    invoices: routeTranslations.invoices,
    profileSettings: routeTranslations.profileSettings,
    accountSettings: routeTranslations.accountSettings,
    chat: routeTranslations.chat,
    repertory: routeTranslations.repertory,
};

const routes: Array<RouteRecordRaw> = [
    {
        path: '/' + routePrefix,
        component: DefaultPage,
        children: [
            {
                path: routePaths.home,
                name: routePrefix + '.home',
                component: HomeView,
            },
            {
                path: routePaths.findTranslator,
                name: routePrefix + '.findTranslator',
                component: () => import(/* webpackChunkName: "findTranslator" */ '../views/FindTranslator.vue'),
            },
            {
                path: routePaths.forTranslators,
                name: routePrefix + '.forTranslators',
                component: () => import(/* webpackChunkName: "findTranslator" */ '../views/ForTranslators.vue'),
            },
            {
                path: routePaths.translatorPage,
                name: routePrefix + '.translatorPage',
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/TranslatorPage.vue')
            },

            //AUTH ROUTES//
            {
                path: routePaths.login,
                name: routePrefix + '.login',
                meta: {
                    isRegistration: false,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/LoginAndRegistrationView.vue')
            },
            {
                path: routePaths.registration,
                name: routePrefix + '.registration',
                meta: {
                    isRegistration: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/LoginAndRegistrationView.vue')
            },
            {
                path: routePaths.registrationEmailVerification,
                name: routePrefix + '.registrationEmailVerification',
                meta: {
                    isRegistration: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/RegistrationEmailVerification.vue')
            },
            {
                path: routePaths.registrationTranslatorVerification,
                name: routePrefix + '.registrationTranslatorVerification',
                meta: {
                    isRegistration: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/RegistrationTranslatorVerification.vue')
            },
            {
                path: routePaths.forgotPassword,
                name: routePrefix + '.forgotPassword',
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/ForgotPasswordView.vue')
            },
            {
                path: routePaths.changePassword,
                name: routePrefix + '.changePassword',
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/ChangePasswordView.vue')
            },
            {
                path: routePaths.logout,
                name: routePrefix + '.logout',
                component: {
                    beforeRouteEnter(to, from, next) {
                        const userService = new UserService();
                        userService.logout();
                    }
                }
            },

            //TRANSLATION PROFILE //
            {
                path: routePaths.inquiries,
                name: routePrefix + '.inquiries',
                meta: {
                    dashboard: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/InquiriesPage.vue')
            },
            {
                path: routePaths.orders,
                name: routePrefix + '.orders',
                meta: {
                    dashboard: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/OrdersPage.vue')
            },
            {
                path: routePaths.invoices,
                name: routePrefix + '.invoices',
                meta: {
                    dashboard: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/InvoicesPage.vue')
            },
            {
                path: routePaths.profileSettings,
                name: routePrefix + '.profileSettings',
                meta: {
                    dashboard: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/translator/TranslatorProfileSettings.vue')
            },
            {
                path: routePaths.accountSettings,
                name: routePrefix + '.accountSettings',
                meta: {
                    dashboard: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/AccountSettings.vue')
            },
            {
                path: routePaths.chat,
                name: routePrefix + '.chat',
                meta: {
                    dashboard: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/translator/ChatPage.vue')
            },
        ]
    },
];

export default routes;