export function parseUrl(string: string) {
    const str = string;

    const replacements = {
        'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z',
        'Ą': 'A', 'Ć': 'C', 'Ę': 'E', 'Ł': 'L', 'Ń': 'N', 'Ó': 'O', 'Ś': 'S', 'Ź': 'Z', 'Ż': 'Z'
    };

    return str.replace(/[:;]/g,'-')
        .replaceAll(' ', '-')
        .replaceAll(',', '')
        .replace(/[ąćęłńóśźż]/gi, (letter) => replacements[letter])
        .toLowerCase();

}

export function unparseUrl(str: string) {
    return str.replace(/[,:;-]/g,' ')
        .replaceAll('-', '')
        .toLowerCase();
}