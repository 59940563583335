import {createApp, markRaw} from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "@/i18n"
import {createPinia} from "pinia";
import VueGoogleMaps from '@fawmi/vue-google-maps'
import ApiHelper from "@/helpers/apiHelper";
const pinia = createPinia();
import mitt from 'mitt';
const emitter = mitt();
//allows to use router in store
pinia.use(({ store }) => {
    store.$router = markRaw(router)
});

const app = createApp(App)
    .use(i18n)
    .use(router)
    .use(VueGoogleMaps, {
        load: {
            // key: 'AIzaSyDXGBjQXMDNfFyAOCKQsgUVWFJQeRWEC0g', //Nasz prywatny klucz
            key: 'AIzaSyAhWpwChEddbhvurq7H6DyDY0DHegf9_qw', // Klucz od sworn. Działa na porcie 8080 na localhost
        },
    })
    .use(pinia)
    .provide('emitter', emitter)
    .mount('#app');

ApiHelper.axiosCheckGlobalResponseStatus();
