
import {ref, reactive, onMounted, toRefs, watch} from 'vue';
import arrowDown from '@/assets/icons/arrow_down.svg';

export default {
    props: {
        horizontalLabel: {
            type: Boolean,
            default: false
        },
        hiddenLabel: {
            type: Boolean,
            default: false
        },
        placeholderText: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            require: true,
            default: 'search',
        },
        customClass: {
            type: [String, Object],
            default: '',
        },
        items: {
            type: Array,
            default() {
                return []
            }
        },
        itemsNameKey: {
            type: String,
            default: 'id',
        },
        itemsValueKey: {
            type: String,
            default: 'value',
        },
        icon: {
            type: String,
            default: '/img/arrow_down.6b335479.svg',
        },
        selectedItem: {
            type: [String,Number],
            default: '',
        },
        selectedItemKey: {
            type: String,
            default: 'value',
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        callback: {
            type: [Function,String],
            default: null
        },
        noComponentMargin: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, {emit}) {
        let searchTerm = ref('');
        const searchItems: any = ref([]);
        let {items, selectedItem} = toRefs(props);
        let selectedValue: any = reactive({});
        let loadingAnswer = ref(false);
        const isValid = ref(true);
        let searchFirstTime = false,
            emitSelect = false;

        function isNumber(n) {
            return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
        }

        watch(items,(curr,old) => {
            setDefaultItem();
        });
        watch(selectedItem,(curr,old) => {
            setDefaultItem();
        });

        const searchDebounce = debounce(() => searchItemsFunction());
        
        watch(searchTerm, (curr,old) => {
            loadingAnswer.value = true;
            searchDebounce();

            // if(curr == '')
            //     emit('onSelect', '');
        })

        function debounce(func, timeout = 300) {
            let timer;
            return function (this: any, ...args: any[]) {
                clearTimeout(timer);
                timer = setTimeout(() => { func.apply(this, args); }, timeout);
            };
        }

        const searchItemsFunction = async () => {

            if(searchFirstTime)
                emit('onInput', {search: searchTerm.value});

            searchFirstTime = true;

            if (searchTerm.value === '' || searchTerm.value == selectedValue.name) {
                loadingAnswer.value = false;
                if(searchTerm.value === '')
                    setDefaultItem(false);
                return items;
            }

            selectedValue!.name = '';

            let matches = 0
            let result = items.value.filter(item => {
                if (item[props.itemsNameKey].toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 20) {
                    matches++;
                    return item;
                }
            });

            if(typeof props.callback == 'function' && searchTerm.value != '') {
                result = [];
                //todo: error
                await props.callback().then(res => {
                    res.forEach((item) => {
                        result.push(item);
                    });
                });

                // result = result.filter(item => {
                //     console.log(item)
                //     console.log('searchTerm.value',searchTerm.value)
                //     if (item[props.itemsNameKey].toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 20) {
                //         matches++;
                //         return item;
                //     }
                // });
            }

            loadingAnswer.value = false;
            searchItems.value = result;
        }

        const showResult = ref(false);

        const closeAutocomplete = () => {
            showResult.value = false;
                document.getElementById(props!.id)!.focus();
        }

        const openAutocompleteList = () => {
            showResult.value = true;
                document.getElementById(props!.id)!.focus();
        }

        const selectItem = (itemValue, itemName, item) => {
            selectedValue.value = itemValue;
            selectedValue.name = itemName;
            selectedValue.item = item;

            //clear result if necessary
            searchTerm.value = itemName;
            showResult.value = false;
            document.getElementById('autocomplete_' + props!.id)!.setAttribute('tabindex', '-1');
            document.getElementById(props!.id)!.focus();
            emitSelect = true;
            emit('onSelect', {value: itemValue, name: itemName, item: item});
        }

        onMounted(() => {
            setDefaultItem();
            document.addEventListener('click', (e: any) => {
                const targetID = e?.target?.id;
                if (targetID != props.id)
                    showResult.value = false;
            })
        })

      const search = (withFocus = true) => {
        showResult.value = true;
        loadingAnswer.value = false;
        setTimeout(() => {
            document.getElementById('autocomplete_' + props.id)!.setAttribute('tabindex', '0');
            if (withFocus)
                document.getElementById('autocomplete_' + props.id)!.focus();
        }, 0);
      }

        function clearInput() {
            // searchTerm.value = '';
        }

      function setDefaultItem(checkDefaultItem = true) {
          let selectedItemTemp = selectedItem.value;

          if(checkDefaultItem) {
              items.value.filter(item => {
                  if (isNumber(selectedItem.value) || selectedItem.value === 0)
                      selectedItemTemp = selectedItem.value;

                  if (item[props.selectedItemKey] === selectedItemTemp) {
                      searchTerm.value = item[props.itemsNameKey];
                      selectedValue.name = item[props.itemsNameKey];
                      selectedValue.value = item[props.itemsValueKey];
                      selectedValue.item = item;
                  }
              });
          }

          if((selectedValue.value || selectedValue.value === 0) && !emitSelect) {
            emit('onSelect', {value: selectedValue.value, name: selectedValue.name, item: selectedValue.item});
          }
      }

        return {
            searchTerm,
            searchItems,
            selectItem,
            selectedValue,
            showResult,
            search,
            closeAutocomplete,
            searchItemsFunction,
            loadingAnswer,
            arrowDown,
            isValid,
            clearInput,
        }
    },

}
