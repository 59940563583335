import {defineStore} from 'pinia'
import axios from "axios";
import ApiHelper from "@/helpers/apiHelper";
import router from "@/router";

interface Pins {
    id: number,
    la: number,
    lo: number
}

interface Coordinate {
    lat?: number,
    lng?: number,
}


export const useSearchTranslatorStore = defineStore({
    id: 'searchTranslator',
    state: () => {
        return {
            isLoading: false,
            pageSize: 10,
            totalCount: 0,
            totalPageCount: 0,
            allMarkers: [] as string[],
            translatorsInfo: [] as string[],
            pins : [],
        }
    },
    getters: {
      getAllMarkers: (state) => {
          const markers: any = [];
          state.allMarkers = [];
          state.pins.forEach((elem: Pins,index) => {
              const item = {
                  id: elem.id,
                  position: {
                      lat: elem.la,
                      lng: elem.lo,
                  },
                  icon: require('../assets/icons/map_icon_outline.png'),
              }
              if(index < state.pageSize)
                markers.push(item);
          });
          // state.allMarkers.push(...markers);
          state.allMarkers = markers;

        return state.allMarkers;
      }
    },
    actions: {
        fetchTranslators(language: number, coords?: Coordinate, type?: number | string,) {
            this.isLoading = true;
            const typeParams = (type && type > 0) ? `&type=${type}` : '';
            const coordinateParams = (coords && Object.keys(coords).length !== 0) ? `&latitude=${coords.lat}&longitude=${coords.lng}` : '';
            const interpreting = (router.currentRoute.value.query?.interpreting) ? '&interpretation=true' : ''

                return axios.get(ApiHelper.getTranslators(language) + `?pageSize=${this.pageSize}` + typeParams + coordinateParams + interpreting)
                    .then(result => {
                        this.pins = result.data.pins;
                        this.translatorsInfo = result.data.result;
                        this.isLoading = false;
                        this.totalCount = result.data.totalCount;
                        this.totalPageCount = result.data.totalPageCount;
                        this.getAllMarkers;
                    })
                    .catch(error => {
                        this.isLoading = false;
                    });
            //this.pageSize = this.pageSize + 5;
        },
        // loadMoreTranslators(language: number, coords: Coordinate, type?: number,) {
        //     this.pageSize = this.pageSize + 5;
        //     this.isLoading = true;
        //     axios.get(ApiHelper.getTranslators(language) + `?pageSize=${this.pageSize}`)
        //         .then(result => {
        //             this.pins = result.data.pins;
        //             this.translatorsInfo = result.data.result;
        //             this.isLoading = false;
        //             this.totalCount = result.data.totalCount;
        //             this.totalPageCount = result.data.totalPageCount;
        //             this.getAllMarkers;
        //             console.log('Ładuje więcej', result);
        //         })
        //         .catch(error => {
        //             this.isLoading = false;
        //         })
        // }
    },
});
