<template>
    <footer class="pt-6 pb-6 bg-gray-300 md:bg-black md:text-white md:absolute w-full md:bottom-0">
        <div class="container px-4 mx-auto flex justify-between flex-col-reverse md:flex-row mb-16 sm:mb-0">
            <p class="text-sm md:text-base pt-2 md:pt-0">&#169; SwornApp</p>
            <div class="flex flex-wrap">
                <a href="https://swornapp.pl/kontakt" class="pr-3">{{ $t('pages.contact') }}</a>
                <span class="pr-3">&bull;</span>
                <a href="https://swornapp.pl/regulamin" class="pr-3">{{ $t('pages.regulations') }}</a>
                <span class="pr-3">&bull;</span>
                <a href="https://swornapp.pl/polityka-prywatnosci" class="pr-3 text-left">{{ $t('pages.privacyPolicy') }}</a>
                <span class="pr-3">&bull;</span>
                <a href="https://swornapp.pl/fundusze-europejskie" class="pr-3 text-left">{{ $t('pages.europeanFunds') }}</a>
            </div>
        </div>
    </footer>
</template>

<style lang="scss">
.search-icon {
    height: 1.065625rem;
}
.account-icon {
    height: 1.25rem;
}
.mobile-menu-icon {
    height: 1.25rem;
}

@media (max-width: 389px) {
    .fontEnlarge-1 footer, {
        margin-bottom: 4rem;
    }

    .fontEnlarge-2 footer {
        margin-bottom: 4.5rem;
    }
}

</style>