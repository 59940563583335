export default {
    pages: {
        forTranslators: "For translators",
        account: "Account",
        contact: "Contact us",
        regulations: "Terms of use",
        enquiries: "Inquiries",
        search: "Search",
        privacyPolicy: "Privacy Policy",
    },
    header: {
        closeAlt: "Close",
        logout: "Sign out",
        accountIconAlt: "Account icon",
        searchIconAlt: "Search icon",
        searchTranslator: "Find a translator",
        selectPageLang: "Select preferred language",
    },
    footer: {
        accountIconAlt: "Account icon",
        searchIconAlt: "Search icon",
        messageIconAlt: "Message icon",
        enquiriesIconAlt: "Translation inquiries icon",
    },
    searchSection: {
        langLabel: "Language",
        translationTypeLabel: "Type of translation",
        locationLabel: "Location",
        defaultTranslationType: "Any",
        search: "Find",
        resultsCounter: "result(s)",
        outOf: "out of",
        list: "List",
        map: "Map",
        showFilters: "Show filters",
        hideFilters: "Hide filters",
        interpreting: "Only interpreting",
    },
    wcag: {
        goToMain: "Skip to main content",
        resizeFont: "Change the font size. The current size is {current}, with {max} being the maximum value",
        googleMap: "Map showing where translators are located",
        interpreting: "Select only interpreting"
    },
    homeView: {
        findSwornTranslator: "Find a sworn translator",
        imageOfSwornTranslatorAlt: "An image showing a sworn translator",
        howItWorks: "How it works",
        itIsEasyText: "It’s easy whether you’re a private individual or are representing a business or public authority (governmental agency, court, the Police). You can use our search engine to find any sworn translator registered in the Ministry of Justice database.",
        arrowIconAlt: "Arrow icon",
        searchEngineIconAlt: "Search engine icon",
        searchEngineInfo: "Find a translator offering the service you are looking for",
        contactTheTranslatorIconAlt: "Phone-ringing icon",
        contactTheTranslatorInfo: "Contact the selected translator to agree the terms of the translation service",
        sendingDocumentsIconAlt: "Sending documents icon",
        sendingDocumentsInfo: "In order to be able to estimate the cost and delivery date of the translation, the translator may ask you to send him or her the original documents first.",
        signedTranslationsIconAlt: "Signed documents icon",
        signedTranslationsInfo: "You will receive a translation certified with the translator’s seal and traditional signature or e-signature",
        frequentlyChosenTranslators: "The most popular translators",
        showMore: "Show more.",
        whoIsSwornTranslator: "Who is a sworn translator",
        personOfPublicTrust: "A sworn translator is a chartered professional who specializes in translating various documents, including pleadings and other legal instruments, and can also certify copies of original documents in a foreign language and translations and such copies made by others.",
        mustCertifyTheComplianceOfTheDocument: "To certify a translation, a sworn translator is required to affix to the translated document his or her official seal featuring the translator’s full name, the language he or she is licensed for, and his or her registration number on the list of sworn translators.",
        certifiedTranslationsInclude: "Foreign-language documents which typically require certified translation include:",
        proceduralAndOfficialDocuments: "pleadings and other legal instruments",
        workCertificates: "employment reference letters",
        schoolCertificates: "graduation certificates and diplomas",
        notarialDeeds: "notarial deeds",
        civilStatusRecords: "vital records",
        lawsAndRegulations: "pieces of legislation",
        certificates: "certificates, confirmations, etc.",
        imageOfMobileAppAlt: "An image showing a mobile phone with the app",
        downloadMobileApp: "Download the mobile app",
        mobileAppDescription: "Nascetur amet magna mattis aliquam ac sit. Sit lobortis nibh quam ultrices dictum commodo scelerisque cras. Ultricies tristique adipiscing sagittis est tincidunt pellentesque. Consectetur accumsan, lobortis ullamcorper ac condimentum sodales velit. Parturient at fermentum, orci quisque mauris scelerisque gravida nulla auctor.",
        appStoreAlt: "App Store",
        googlePlayAlt: "Google Play",
        logo_unia: "European flag logo",
        unia_section: "European Union, Republic of Poland and Project logotypes",
    },
    forTranslators: {
        forSwornTranslators: "For sworn translators",
        signUpIfYouAreTranslator: "Are you a translator? Sign up and activate a VIP account to access the full range of our app’s useful features",
        swornAppIsDedicatedForTranslators: "The SwornApp is dedicated to sworn translators!",
        benefits: "Features",
        free: "Free",
        standard: "Standard",
        vip: "VIP",
        convenientCommunication: "Convenient communication and document exchange with customers",
        abilityToSpecifyPreferredServices: "Option to specify your preferred service offering",
        editingBusinessCard: "Fully editable business card",
        keepingOfTheRepertory: "Option to maintain your official register of certified translations online",
        convenientInvoicing: "Convenient invoicing",
        onlinePaymentsForCustomers: "Online payments from customers",
        checkMarkIconAlt: "Check mark icon",
        notAvailableIconAlt: "not available icon",
        month: "month",
        freeVipAccount: "Install the SwornApp now to get a one-year long access to a VIP account for PLN 0",
        crateVipAccount: "Sign up for a VIP account for PLN 0",
        imageOfSwornTranslatorAlt: "An image showing a sworn translator",
        freeAvailable: "available with the free account",
        freeNotAvailable: "not available with the free account",
        standardAvailable: "available with the standard account",
        standardNotAvailable: "not available with the free account",
        vipAvailable: "available with the vip account",
        vipNotAvailable: "not available with the vip account",
        priceFree: "free account for",
        priceStandard: "standard account for",
        priceVip: "vip account za",
        perMonth: "per month",
    },
    findTranslator: {
        starIconAlt: "Star icon",
        findTranslator: "Find a sworn translator",
        loading: "Loading...",
        showMore: "Show more."
    },
    starsCounter: {
        starIconAlt: "Star icon",
        rating: "Rating",
        ratingOutOf: "out of",
    },
    translatorPage: {
        pointerIconAlt: "Pointer icon",
        mailIconAlt: "Mail icon",
        phoneIconAlt: "Phone icon",
        clockIconAlt: "Clock icon",
        translatorImageAlt: "Translator’s profile picture",
        translationLanguages: "Languages the translator is licensed for",
        askForOffer: "Request an offer",
        aboutMe: "About me",
        aboutTranslator: "About the translator",
        aboutTranslatorContent: "The translator’s details downloaded from the Ministry of Justice database.",
        signUpAsTranslatorInfo: "Is this your business card? Sing up to complete your details!",
        signUpAsTranslatorButton: "Create a translator’s account",
        experience: "Experience",
        currently: "currently",
        education: "Education",
        deadline: "Time limit",
        dateFrom: "since",
        writtenTranslations: "Written translation",
        interpreting: "Interpreting",
        yes: "Yes",
        no: "No",
        showMore: "Show more",
        showLess: "Show less",
        list: "List",
        map: "Map",
        sendRequest: "Send an inquiry",
        sendRequestTo: "to",
        form: {
            language: "Languages",
            formOfTranslation: 'Form',
            kindOf: "Type",
            deliveryMethod: "Original document delivery method",
            paymentMethod: "Payment Method",
            deadline: "Deadline",
            receivingMethod: "Translation delivery method",
            documentFile: "Document or sample",
            address: 'Address',
            fromTime: 'from',
            toTime: 'to',
            date: 'Date',
            addFile: 'Upload a file',
            chooseFile: 'You’ve selected:',
            cancel: "Cancel",
            send: 'Send',
            contentMessage: 'App contents',
            addFilePlaceholder: 'Upload a file',
            enterMessagePlaceholder: "Type your message",
            sendInquiryTo: "Send an inquiry to:",
            yourMessage: "Your message",
            successfullySendInquiry: "Your inquiry has been successfully sent",
            errorSendInquiry: "There was an error sending your inquiry",
        },
    },
    translatorCard: {
        pointerIconAlt: "Pointer icon",
        address: "Address",
        showOnMap: "Show on the map",
        seeProfile: "View the profile",
        deadline: "Time limit",
        clockIconAlt: "Clock icon",
        mailIconAlt: "Mail icon",
        mail: "Email address",
        phone: "Phone",
        phoneIconAlt: "Phone icon",
        arrowRightIconAlt: "Right arrow icon",
        votesNumber: "Number of votes",
        dateFrom: "since",
        deadlineAriaLabel: "Approximate translation delivery date"
    },
    orders: {
        yes: "Yes",
        no: "No",
        search: "Search",
        inProgress: "Projects underway",
        toBePaid: "Projects pending payment",
        ended: "Completed projects",
        principal: "Customer",
        admissionDate: "Acceptance date",
        completionDate: "Completion date",
        translationForm: "Form of translation",
        valuation: "Offer",
        admitted: "Accepted",
        finish: "Finalize",
        reject: "Reject",
        editOrder: "Edit project details",
        status: "Status",
        repertory: "Register of certified translations",
        markAsPaid: "Mark as paid",
        payOnline: "Pay online",
        endOrder: "Finalize the project",
        downloadAnInvoice: "Download an invoice",
        statuses: {
            None: "None",
            InProgress: "Underway",
            Rejected: "Rejected",
            ToBePaid: "Pending payment",
            Completed: "Completed"
        },
        orderFrom: "Ordered by",
        order: "Project",
        translationPrice: "Translation cost",
        additionalServices: "Additional services",
        gross: "VAT-inclusive",
        net: "VAT-exclusive",
        pagesNumber: "Number of pages",
        totalGrossAmount: "Total VAT-inclusive cost",
        totalPriceToPay: "Total amount due",
        endTime: "Completion date",
        paymentDeadline: "Due date",
        details: {
            language: "Language",
            formOfTranslation: "Form",
            typeOfTranslation: "Type",
            deadline: "Deadline",
            originalDocumentDelivery: "Original document delivery method",
            receiptOfTranslation: "Translation delivery method",
            paymentMethod: "Payment Method",
            methodOfCalculating: "Cost calculation method",
            currency: "Currency",
        },
        markAsPaidModal: {
            title: "Payment receipt confirmation",
            description: "Are you sure you wish to confirm receiving payment for this project?",
            markedAsPaid: "The project has been marked as paid",
        },
        rejectOrderModal: {
            titleVIP: "Do you wish to add the rejected project to your register of certified translations? ",
            titleBasic: "Project rejection confirmation",
            descriptionBasic: "Are you sure you wish to reject this project?",
            orderSuccessfullyRejected: "The project has been rejected",
            cancelButton: "Cancel",
            rejectButton: "Reject this project",
            createRepertory: "Yes",
            dontCreateRepertory: "No",
            createRepertoryAlt: "Add to the register of certified translations",
            dontCreateRepertoryAlt: "Do not add to the register of certified translations",
            selectIconAlt: "Selection icon",
        },
        endOrderModal: {
            saleDate: "Date of slae",
            issueDate: "Date issued",
            paymentDate: "Due date",
            endOrderButton: "Finalize the project",
            endingOrderButton: "Finalize the project...",
            issueInvoice: "Issue an invoice",
            provideDataForInvoice: "Enter the details of the customer to be invoiced",
            customerName: "Full name",
            nip: "Tax ID Number",
            street: "Street name",
            buildingNumber: "Building number",
            flatNumber: "Suite number",
            city: "Town/city",
            postcode: "Zip code",
            serviceName: "Service name",
            pagesNumber: "Number of pages",
            rate: "Rate",
            gross: "VAT-inclusive",
            totalAmount: "Total",
            plnGrossPrice: "VAT-inclusive amount in PLN",
            addAdditionalService: "Add an additional service",
            customerAskedForAnInvoice: "The customer has requested an invoice",
            totalPriceToPay: "Total amount due",
            addToRepertory: "Add to the register of certified translations",
            priceWithVat: "VAT-inclusive prices",
            vatRate: "VAT rate"
        }
    },
    invoices: {
        search: "Search",
        issuedInvoices: "Issued invoices",
        customer: "Customer",
        admissionDate: "Acceptance date",
        endDate: "Completion date",
        translationForm: "Form of translation",
        price: "Cost",
        status: "Status",
        downloadAnInvoice: "Download an invoice",
        admitted: "Accepted",
    },
    inquiries: {
        today: "Today at ",
        to: "To",
        search: "Search",
        ordersFrom: "Projects from",
        messages: "Messages",
        date: "Date",
        chat: {
            language: "Language",
            formOfTranslation: "Form",
            typeOfTranslation: "Type",
            deadline: "Deadline",
            originalDocumentDelivery: "Original document delivery method",
            receiptOfTranslation: "Translation delivery method",
            paymentMethod: "Payment Method",
            methodOfCalculatingId: "Cost calculation method",
            currency: "Currency",
            typeOfRate: "Type of rate",
            grossRate: "Rate (PLN, VAT-inclusive)",
            vatRate: "VAT rate",
            expiryDate: "Offer expiry date",
            orderFrom: "Ordered by",
            methodOfCalculating: "Cost calculation method",
            priceNet: "Rate (PLN, VAT-exclusive)",
            priceGross: "Rate (PLN, VAT-inclusive)",
            net: 'VAT-inclusive',
            gross: "VAT-exclusive",
            successMakeOffer: "Your offer has been successfully sent",
            failMakeOffer: "An error occurred while creating your offer",
            successEditOffer: "Your offer has been successfully edited",
            failEditOffer: "An error occurred while editing the offer",
            successAcceptOffer: "The offer has been successfully accepted",
            failAcceptOffer: "An error occurred while accepting the offer",
            address: "Address",
            dates: 'Dates',
            date: 'Date',
            timeTo: 'to',
            timeFrom: 'from',
            successCloseInquiries: 'The inquiry has been successfully closed',
            failCloseInquiries: 'An error occurred while closing the inquiry',
            yes: 'Yes',
            no: 'No',
            successEditInquiries: "The inquiry has been successfully edited",
            failEditInquiries: "An error occurred while editing the inquiry",
            successRejectOfferAndCloseInquiries: "The offer has been successfully rejected and the inquiry closed",
            failRejectOfferAndCloseInquiries: "An error occurred while rejecting your offer.",

            button: {
                cancel: 'Cancel',
                makeOffer: "Present your offer",
                sendOffer: "Send your offer",
                edit: "Edit",
                updates: "Update",
                editOffer: "Edit",
                acceptOffer: "Accept",
                rejectAndCloseOffer: "Reject and close",
                close: 'Close',
                rejectInquirie: 'Reject the inquiry',
                sendMessage: 'Send message',
                putMessage: "Type your message",
            },

            confirm: {
                rejectTitle: 'Reject and close the inquiry',
                rejectDescription: 'Are you sure you wish to reject this inquiry?',
                closeTitle: 'Close the inquiry',
                closeDescription: 'Are you sure you wish to close this inquiry?',
                rejectAndAddToRepertory: 'Do you wish to add the rejected project to your register of certified translations?',

            },

            validation: {
                language: 'The language you have entered does not exist',
                formOfTranslation: 'The form you have entered does not exist',
                typeOfTranslation: 'The type you have entered does not exist',
                failSendFile: 'File transfer error',
                checkExtensions: "Check the file extensions",
            }
        },

    },
    notFoundPage: {
        error404: "Error 404",
        pageNotExist: "There is no such site",
        backToHomePage: "Go back to homepage.",
        pageNotFoundIconAlt: "Page 404 icon",
    },
    modal: {
        close: "Close"
    },
    autocomplete: {
        loading: "Loading...",
        noResult: "No results"
    },
    loginAndRegistrationView: {
        title: "Sign in or create an account",
        login: "Sign in",
        registration: "Register",
        eyeIconAlt: "Eye icon",
        showPassword: "Show password",
        hidePassword: "Hide password",
    },
    login: {
        email: "Email",
        password: "Password",
        loginButton: "Sign in",
        forgotPassword: "Forgot your password"
    },
    registration: {
        accountActivatedAlt: "Successful activation icon",
        customerOrCompany: "Individual or business customer",
        publicInstitution: "Public authority",
        swornTranslator: "Sworn translator",
        selectIconAlt: "Selection icon",
        email: "Email",
        businessEmail: "Business email",
        businessPhone: "Business phone",
        institutionName: "Authority (accurate and complete name)",
        institutionWebsite: "Authority’s WWW address",
        position: "Position",
        name: "Forename",
        surname: "Surname",
        password: "Password",
        translatorNumber: "Enter your translator licence number (e.g. TP/1234/00)",
        regulations_acceptance_start: "I agree to",
        regulations_acceptance_link: "the Terms of Use",
        regulations_acceptance_end: "and the processing of my data...",
        signUpButton: "Sign up",
        next: "Next",
        confirmPhone: "Confirm your phone number",
        confirmationByLetter: "Thank you! You will receive a confirmation letter from us",
        confirmEmail: "Confirm your email address",
        confirmEmailInfo: "We have sent you a message. Click on the link included in it to complete your registration.",
        confirmTranslatorEmailInfo: "We have sent you a message to your email address featured in the Ministry of Justice database:",
        clickLinkToRegister: "Click on the link included in it to complete your registration.",
        confirmTranslatorPhoneInfo: "We have sent you a text message with a confirmation code to your phone number featured in the Ministry of Justice database:",
        confirmTranslatorLetterInfo: "Within a couple of days, we will send you a confirmation code by registered mail to your mailing address featured in the Ministry of Justice database.",
        linkSentIconAlt: "Link sent icon",
        accountActivated: "Your account has been activated",
        goToYourAccount: "Go to your account",
        verificationError: "Verification error",
        sendNewLink: "Send a new link",
        sending: "Sending...",
        backToHomePage: "Go back to homepage.",
        goFurther: "Go forward",
        enterEmail: "Enter your email address",
        translatorVerificationButton: "Do you have a confirmation code?",
        translatorVerification: {
            verifyAndFillData: "Verify and complete your details",
            enterTpNumber: "Enter your translator licence number",
            enterVerificationCode: "Enter your confirmation code",
            verificationCode: "Confirmation code",
            enterEmail: "Enter your email address",
        },
    },
    forgotPassword: {
        title: "Enter the email address to send the change password link to",
        email: "Email",
        enterEmail: "Enter your email address",
        backToLogin: "Go back to the sign-in page",
        next: "Next",
        linkSentTitle: "Check your email for a link to follow to change your password",
        linkSentIconAlt: "Link sent icon",
        linkSentInfo: "We have sent you a message. Follow the link included in it to change your password.",
    },
    changePassword: {
        title: "Enter a new password",
        email: "Email",
        enterEmail: "Enter your email address",
        password: "Password",
        changePasswordButton: "Change password",
        passwordChanged: "Your password has been changed"
    },
    translatorProfile: {
        absence: {
            absenceAtWork: "Out-of-office period",
            setAbsenceInWork: 'Schedule and out-of-office period',
            dateFrom: "From",
            dateTo: "To",
            indefinitely: "Indefinite",
            messageForClients: "Out-of-office message",
            save: "Save",
            successDisableAbsence: "You have successfully removed the out-of-office period",
            successSetAbsence: "You have successfully scheduled an out-of-office period",
            valid: {
                dateGreaterThan: "This date cannot be earlier than the date from of the out-of-office period"
            }
        }

    },
    tabs: {
        hello: "Hello",
        requests: "Inquiries",
        orders: "Projects",
        invoice: "Invoices",
        repertory: "Register of certified translations",
        profile: "Profile",
        settings: "Settings"
    },
    validation: {
        email: "The email address you have entered is invalid",
        required: "Field is required",
        password: "You password must be at least 8 characters long and include at least 1 capital letter, 1 lowercase letter, and 1 special character",
        maxFileSize: "The file {fileName} is too large",
        maximumFiles: "The maximum number of files is: {maxFiles}",
        maximumSize: "The maximum aggregate file size is: {maximumSize}",
        passwordConfirmation: "The password and confirm password must be identical",
        max_nip: "The tax ID number must be 10 digits long",
    },
    errorCodes: {
        2: "Invalid token",
        3: "Invalid token",
        4: "Invalid token",
        5: "You account has been locked",
        6: "Invalid username or password",
        7: "Your email address has not been confirmed",
        8: "A two factor authentication code has been sent",
        9: "User already exists",
        10: "An error has occurred. Please try again later.",
        14: "Your email has already been confirmed",
        15: "The user has been removed",
        default: "An error occurred while processing the request."
    },
    languageSettings: {
        interpreting: "interpreting",
        deadline: "Time limit",
        choosePreferredServices: "Choose your preferred service offering",
        dateFrom: "from"
    },
    profileSettings: {
        aboutMe: "About me",
        save: "Save",
        saving: "Saving...",
        edit: "Edit",
        cancel: "Cancel",
        change: "Change",
        delete: "Delete",
        deleting: "Deleting...",
        add: "Add",
        yourPhoto: "Your photo",
        profilePage: "Profile picture",
        addFilePlaceholder: "Add file",
        choseFile: "File chosen",
        experience: {
            title: "Experience",
            companyName: "Trading name",
            location: "Location",
            position: "Position",
            workStart: "Start date",
            workEnd: "End date",
            workingHereCurrently: "This is where I currently work",
            currently: "currently"
        },
        education: {
            title: "Education",
            school: "School",
            specialization: "Specialization",
            academicDegree: "Academic degree",
            workStart: "Start date",
            workEnd: "End date (or scheduled end date)",
        },
    },
    settings: {
        save: "Save",
        saving: "Saving...",
        edit: "Edit",
        cancel: "Cancel",
        change: "Change",
        delete: "Delete",
        deleting: "Deleting...",
        add: "Add",
        arrowIconAlt: "Arrow icon",
        addresses: {
            title: "Addresses",
            lockedAddress: "Address locked",
            lockIconAlt: "Padlock icon",
            defaultAddress: "Default address",
            setAsDefaultAddress: "Set as the default address",
            settingAsDefaultAddress: "Setting as the default address...",
            street: "Street name",
            buildingNumber: "Building number",
            flatNumber: "Suite number",
            city: "Town/city",
            postcode: "Zip code",
        },
        emails: {
            title: "Email addresses",
            lockedEmail: "Email address locked",
            lockIconAlt: "Padlock icon",
            defaultEmail: "Default email address",
            setAsDefaultEmail: "Set as the default address",
            settingAsDefaultEmail: "Setting as the default address...",
            email: "Email",
        },
        clientEmail: {
            title: "Email address",
            email: "Email",
        },
        phones: {
            title: "Phone numbers",
            lockedPhone: "Phone number locked",
            lockIconAlt: "Padlock icon",
            defaultPhone: "Default phone number",
            setAsDefaultPhone: "Set as the default phone number",
            settingAsDefaultPhone: "Setting as the default phone number...",
            phone: "Phone",
            phonePrefix: "Phone prefix",
            isLandline: "Landline",
        },
        invoicesData: {
            title: "Your invoice details",
            street: "Street name",
            buildingNumber: "Building number",
            flatNumber: "Suite number",
            city: "Location",
            postcode: "Zip code",
            name: "Name",
            nip: "Tax ID Number",
            postOffice: "Post office",
            iAmVatPayer: "I am a VAT payer",
            basicNumeration: "Basic numbering",
            courtsProsecutorsPoliceNumeration: "Numbering for courts, the Prosecution Service, and the Police",
            prefix: "Prefix",
            numerationType: "Type of numbering",
            format: "Format",
            startFrom: "Start from",
            registeredInBdo: "Business registered in the Waste Management Database (BDO)",
            bdoNumber: "Waste Management Database (BDO) number",
            bankAccountsNumbers: "Bank account numbers",
            currency: "Currency",
            country: "Country",
            bankAccountNumber: "Number",
            kindNumberingTypes: {
                1: "Annual",
                2: "Monthly",
            },
            defaultInvoiceData: "Default invoice details",
            setAsDefaultInvoiceData: "Set as default invoice details",
            settingAsDefaultInvoiceData: "Setting as default invoice details...",
        },
        removeAccount: {
            title: "Account removal",
            confirmation: "I confirm I wish to remove my account",
            removed: "Your account has been removed",
            cancelButton: "Cancel",
            confirmButton: "Confirm",
            confirmModalTitle: "Confirm removal of your account",
            confirmModalDescription: "A removed account cannot be reactivated.<br/> Following confirmation, you will be automatically signed out.",
        },
        subscription: {
            title: "Your subscription",
            showYourSubscription: "Show my subscription details",
            to: "to",
            validFrom: "Valid from",
            validTo: "Valid to",
            subscriptionId: "Subscription ID",
        },
        changePassword: {
            title: "Change password",
            currentPassword: "Current password",
            newPassword: "New password",
            repeatPassword: "Repeat password",
            passwordChanged: "Your password has been changed",
        },
    },
    months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    },
    repertory: {
        itemsNeedCompleted: "Items to be completed",
        completedItems: "Completed items",
        adoptionDate: "Acceptance date",
        refusalDate: "Refusal date",
        ordinalNumber: "No. (1)",
        orderingDesignation: "Customer designation",
        adoptionOrderDate: "Project acceptance date (2)",
        orderingDesignationOrPurchaser: "Designation of the customer ordering the translation (3)",
        translationForm: "Form of translation",
        descriptionTranslatedDocument: "Description of the document submitted for translation",
        nameDateAndDesignation: "Name, date, and reference of the document (4)",
        languageDocumentDrawek: "Language of the document (5)",
        commentsOnType: "Comment on the type, form and condition of the document (7)",
        activityDesignation: "Designation of the translation service",
        typeOfActivityPerformed: "Type of the translation service and the target language (8)",
        numberOfPagesOfTranslation: "Number of translation pages (9)",
        numberOfCopiesMade: "Number of copies (10)",
        refusalToProvideTranslation: "Refusal to provide a translation service to an authority listed under Article 15 of the Act on the Profession of a Sworn Translator",
        authorityRequestingTranslation: "Authority requesting translation (17)",
        reasonForRefusalTranslation: "Reason for the refusal (18)",
        remarks: "Remarks",
        interpretationDescription: "Description of interpretation service",
        date: "Date",
        place: "Venue (11)",
        duration: "Duration",
        amountCollectedRemuneration: "Fee charged (14)",
        dateOfReturnDocument: "Date of returning the document accompanied by a translation ( 15)",
        range: "Range",
        edit: "Edit",
        export: "Export",
        search: "Search",

    }
}