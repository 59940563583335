<template>
    <router-view keep-alive></router-view>
</template>

<script>
export default {
    name: "DefaultPage",
}
</script>

<style scoped>

</style>