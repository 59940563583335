export function setCookie(name: string, value?: string, expirationTime?: string, path = "/", secure = true) {
    let expires = '';
    if (expirationTime) {
        const date = new Date(expirationTime);
        expires = "; expires=" + date;
    }
    document.cookie = name + "=" + value + expires + "; path=" + path + (secure ? ";secure" : "");
}

export function getCookie(cname: string) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function removeCookie(name: string) {
    setCookie(name,"","-1");
}
