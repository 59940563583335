import {RouteRecordRaw} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import UserService from "@/services/userService";

const routeTranslations = {
    translators: 'tlumacze',
    forTranslators: 'dla-tlumaczy',
    translator: 'tlumacz',
    login: 'logowanie',
    registration: 'rejestracja',
    verify: 'weryfikuj',
    forgotPassword: 'resetowanie-hasla',
    changePassword: 'zmiana-hasla',
    logout: 'wyloguj',
    inquiries: 'zapytania',
    orders: 'zlecenia',
    invoices: 'faktury',
    profileSettings: 'ustawienia-profilu',
    accountSettings: 'ustawienia',
    chat: 'czat',
    repertory: 'repertorium',
}

const routePaths = {
    home: '',
    findTranslator: routeTranslations.translators + '/:language/:city?/:types?',
    forTranslators: routeTranslations.forTranslators,
    translatorPage: routeTranslations.translator + '/:nameSlug/:tpNumberSlug',
    login: routeTranslations.login,
    registration: routeTranslations.registration,
    registrationEmailVerification: routeTranslations.registration + '/' + routeTranslations.verify + '/:userEmail',
    registrationTranslatorVerification: routeTranslations.registration + '/' + routeTranslations.translator + '/' + routeTranslations.verify + '/:tpNumber?',
    forgotPassword: routeTranslations.forgotPassword,
    changePassword: routeTranslations.changePassword + '/:userEmail',
    logout: routeTranslations.logout,
    inquiries: routeTranslations.inquiries,
    orders: routeTranslations.orders,
    invoices: routeTranslations.invoices,
    profileSettings: routeTranslations.profileSettings,
    accountSettings: routeTranslations.accountSettings,
    chat: routeTranslations.chat,
    repertory: routeTranslations.repertory,
};

const routes: Array<RouteRecordRaw> = [
    {
        path: '/' + routePaths.home,
        name: 'home',
        component: HomeView,
        meta: {
            defaultLang: true,
            title: 'Znajdź tłumacza',
            description: "Znajdź mi tłumacza przysięgłego"
        },
    },
    {
        path: '/' + routePaths.findTranslator,
        name: 'findTranslator',
        meta: {
            defaultLang: true,
        },
        component: () => import(/* webpackChunkName: "findTranslator" */ '../views/FindTranslator.vue'),
    },
    {
        path: '/' + routePaths.forTranslators,
        name: 'forTranslators',
        meta: {
            defaultLang: true,
        },
        component: () => import(/* webpackChunkName: "forTranslators" */ '../views/ForTranslators.vue')
    },
    {
        path: '/' + routePaths.translatorPage,
        name: 'translatorPage',
        meta: {
            defaultLang: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/TranslatorPage.vue')
    },

    //AUTH ROUTES//
    {
        path: '/' + routePaths.login,
        name: 'login',
        meta: {
            defaultLang: true,
            isRegistration: false,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/LoginAndRegistrationView.vue')
    },
    {
        path: '/' + routePaths.registration,
        name: 'registration',
        meta: {
            defaultLang: true,
            isRegistration: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/LoginAndRegistrationView.vue')
    },
    {
        path: '/' + routePaths.registrationEmailVerification,
        name: 'registrationEmailVerification',
        meta: {
            defaultLang: true,
            isRegistration: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/RegistrationEmailVerification.vue')
    },
    {
        path: '/' + routePaths.registrationTranslatorVerification,
        name: 'registrationTranslatorVerification',
        meta: {
            defaultLang: true,
            isRegistration: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/RegistrationTranslatorVerification.vue')
    },
    {
        path: '/' + routePaths.forgotPassword,
        name: 'forgotPassword',
        meta: {
            defaultLang: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/ForgotPasswordView.vue')
    },
    {
        path: '/' + routePaths.changePassword,
        name: 'changePassword',
        meta: {
            defaultLang: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/auth/ChangePasswordView.vue')
    },
    {
        path: '/' + routePaths.logout,
        name: 'logout',
        meta: {
            defaultLang: true,
        },
        component: {
            beforeRouteEnter(to, from, next) {
                const userService = new UserService();
                userService.logout();
            }
        }
    },

    //TRANSLATION PROFILE //
    {
        path: '/' + routePaths.inquiries,
        name: 'inquiries',
        meta: {
            defaultLang: true,
            dashboard: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/InquiriesPage.vue')
    },
    {
        path: '/' + routePaths.orders,
        name: 'orders',
        meta: {
            defaultLang: true,
            dashboard: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/OrdersPage.vue')
    },
    {
        path: '/' + routePaths.invoices,
        name: 'invoices',
        meta: {
            defaultLang: true,
            dashboard: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/InvoicesPage.vue')
    },
    {
        path: '/' + routePaths.profileSettings,
        name: 'profileSettings',
        meta: {
            defaultLang: true,
            dashboard: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/translator/TranslatorProfileSettings.vue')
    },
    {
        path: '/' + routePaths.accountSettings,
        name: 'accountSettings',
        meta: {
            defaultLang: true,
            dashboard: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/AccountSettings.vue')
    },
    {
        path: '/' + routePaths.chat,
        name: 'chat',
        meta: {
            defaultLang: true,
            dashboard: true,
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/translator/ChatPage.vue')
    },
];

export default routes;