import { createI18n } from "vue-i18n";
import pl from '@/i18n/lang/pl';
import en from '@/i18n/lang/en';
import uk from '@/i18n/lang/uk';
import de from '@/i18n/lang/de';
import ru from '@/i18n/lang/ru';
import storeCache from '@/helpers/storeCache';
import {ref, watch} from 'vue';
import router, {getRouteName} from "@/router";
import {storeToRefs} from "pinia";
import {useFrequentlySearchedTranslatorsStore} from "@/stores/frequentlySearchedTranslators.store";
import {useSearchTranslatorFiltersStore} from "@/stores/searchTranslatorFilters.store";
import {useSearchTranslatorStore} from "@/stores/searchTranslator.store";
import {clearBasicStores} from "@/helpers/piniaHelper";

const messages = {
    'pl': pl,
    'en': en,
    'uk': uk,
    'de': de,
    'ru': ru,
};

export const availableLang: object = {
    'pl': '',
    'en': '',
    'uk': '',
    'de': '',
    'ru': '',
};


const lang = ref('pl');

const cache = storeCache;

export function defaultLang() {
    return 'pl';
}

export function getLangConfig() {

    //TODO sprawdzić czemu cache wywala stronę;
    // if (cache.get('lang'))
    //     lang.value = cache.get('lang');

    if(!Object.prototype.hasOwnProperty.call(availableLang,lang.value))
        lang.value = 'pl'

    document.querySelector('html')!.lang = lang.value;

    return lang.value;

}

export function setLangConfig(language: any, withRouteChange = true ) {
    cache.set('lang', language);
    i18n.global.locale = language;
    lang.value = language;
    document.querySelector('html')!.lang = language

    let basicRouteName = '';
    if (withRouteChange) {
        let routeName = router.currentRoute.value.name ?? '';
        routeName = routeName.toString();
        if (router.currentRoute.value.meta.defaultLang) {
            basicRouteName = routeName;
            if (language != defaultLang())
                routeName = language + '.' + routeName;
        } else {
            const routeNameParts = routeName.split('.');
            basicRouteName = routeNameParts[1];
            if (language == defaultLang())
                routeName = routeNameParts[1];
            else
                routeName = language + '.' + routeNameParts[1];
        }
        router.currentRoute.value.params.locale = language;

        const searchTranslatorFiltersStore = useSearchTranslatorFiltersStore();
        searchTranslatorFiltersStore.$reset();
        useFrequentlySearchedTranslatorsStore().$reset();
        useSearchTranslatorStore().$reset();
        clearBasicStores();
        if (basicRouteName == 'findTranslator' || basicRouteName == 'home') {

            const { availableLangs, availableTranslationTypes, availableLocations } = storeToRefs(searchTranslatorFiltersStore);
            Promise.all([
                searchTranslatorFiltersStore.fetchAvailableLangs(true),
                searchTranslatorFiltersStore.fetchAvailableTranslationsTypes(true),
                searchTranslatorFiltersStore.fetchAvailableLocations(true),
            ]).then(res => {
                if (router.currentRoute.value.params.language) {
                    availableLangs.value.filter(item => {
                        if (item['id'] === storeCache.get('searchSelectedLang').value) {
                            router.currentRoute.value.params.language = item['slug']
                        }
                    });
                }
                if (router.currentRoute.value.params.city) {
                    availableLocations.value.filter(item => {
                        if (item['id'] === storeCache.get('searchSelectedCity').value) {
                            router.currentRoute.value.params.city = item['slug']
                        }
                    });
                }
                if (router.currentRoute.value.params.types) {
                    availableTranslationTypes.value.filter(item => {
                        if (item['id'] === storeCache.get('searchSelectedTranslationType').value) {
                            router.currentRoute.value.params.types = item['slug']
                        }
                    });
                }
                router.push({name: routeName, params: router.currentRoute.value.params, query: router.currentRoute.value.query}).then(() => { router.go(0) })
            });
        } else {
            router.push({name: routeName, params: router.currentRoute.value.params}).then(() => { router.go(0) })
        }
    }
}

const i18n = createI18n({
    locale: getLangConfig(),
    fallbackLocale: 'pl',
    formatFallbackMessages: true,
    messages: messages,

});

export default i18n;