<template>
    <div class="flex items-center flex-wrap justify-around md:mt-4 md:mt-0">
<!--        <router-link :to="{name: getRouteName('findTranslator')}" class="mr-10 hidden sm:block" :aria-label="$t('header.searchTranslator')">-->
<!--            <img src="@/assets/images/search.svg" :alt="$t('header.searchIconAlt')" class="search-icon">-->
<!--        </router-link>-->
        <router-link :to="{name: getRouteName('forTranslators')}" class="mr-3 sm:mr-10 hidden sm:block">{{ $t('pages.forTranslators') }}</router-link>
        <div role="button" @click="toogleFontSize" @keypress.enter="toogleFontSize" class="mr-3 lg:mr-10 wcag wcag-fontSize" tabindex="0" :aria-label="$t('wcag.resizeFont', {'current': currentFontSize +1 , 'max': 3})">
            <span>A</span>
            <span>A</span>
            <span>A</span>
        </div>
        <select @change="changeLang($event.target.value)" class="sm:mr-10 mr-3" :aria-label="$t('header.selectPageLang')" :value="getLangConfig()">
            <option
                v-for="(lang,index) in getAvailableLang"
                :value="lang"
                :key="index"
            >
                {{lang.toUpperCase()}}
            </option>
        </select>
        <router-link :to="{name: getRouteName('inquiries')}" class="sm:flex items-center hidden">
            <img src="@/assets/images/account.svg" :alt="$t('header.accountIconAlt')" class="pr-3 account-icon">
            <span>{{ $t('pages.account') }}</span>
        </router-link>
        <router-link v-if="Object.keys(authUser?.user).length" :to="{name: getRouteName('logout')}" :aria-label="$t('header.logout')" class="block sm:ml-10">
            <img src="@/assets/images/power-settings-new.svg" :alt="$t('header.logout')">
        </router-link>
    </div>
</template>

<script setup >
import {setLangConfig,getLangConfig} from "@/i18n";
import {ref} from 'vue';
import {getRouteName} from "@/router";
import {useAuthStore} from "@/stores/authStore";

const authUser = useAuthStore();

function changeLang(lang) {
    setLangConfig(lang);
    // setTimeout(() => {
    //     window.history.go()
    // },100)
}

const getAvailableLang = ref([
    'pl',
    'en',
    'uk',
    'de',
    'ru'
]),
    currentFontSize = ref(0);

const toogleFontSize = (withIncrement = true) => {
    let currentIndex = 0;
    const fontEnlargeClass = ['fontEnlarge-0', 'fontEnlarge-1', 'fontEnlarge-2'];

    if (localStorage.getItem('font-enlarge')) {
        currentIndex = parseInt(localStorage.getItem('font-enlarge'));
        if (withIncrement) {
            if (fontEnlargeClass.length > currentIndex + 1) {
                currentIndex = currentIndex + 1;
            } else {
                currentIndex = 0;
            }
        }
    }

    document.querySelector('html').classList.remove(...fontEnlargeClass);
    document.querySelector('html').classList.add(`fontEnlarge-${currentIndex}`);
    localStorage.setItem('font-enlarge', currentIndex);
    currentFontSize.value = currentIndex;
}
toogleFontSize(false);


</script>
<style lang="scss" scoped>
.wcag-fontSize {
    span {
        &:nth-child(1) {
            font-size: 0.6rem;
        }

        &:nth-child(2) {
            font-size: 0.8rem;
        }

        &:nth-child(3) {
            font-size: 1rem;
        }
    }
}

.account-icon {
    height: 1.25rem;
}
</style>