import axios from 'axios';
import ApiHelper from "@/helpers/apiHelper";
import authHelper from "@/helpers/authHelper";
import {useAuthStore} from "@/stores/authStore";
import {getCookie, setCookie} from "@/helpers/cookieHelper";

class UserService {
    /**
     * User logout
     */
    logout() {
        const authStore = useAuthStore();
        authStore.logout();
    }

    /**
     * Refresh user token
     */
    refreshToken() {
        axios.post(ApiHelper.getRefreshTokenUrl(),{
                authToken: getCookie('auth-token'),
                refreshToken: getCookie('auth-refresh'),
            },{
            headers: {'Authorization': authHelper.authHeader()}
            }
        ).then(response => {
            try {
                setCookie('auth-token', response.data.data.accessToken, response.data.data.validTo)
                setCookie('auth-refresh', response.data.data.refreshToken, response.data.data.validTo)
                setCookie('auth-expiration', response.data.data.validTo, response.data.data.validTo)

                return true;
            } catch (error) {
                throw new Error('Error during refresh token. Error: ' + error);
            }
        }).catch(error => {
            console.log("Error during refresh token. Error: ", error);
        })
    }

    checkToken() {
        if (getCookie('auth-token') && getCookie('auth-expiration')) {
            const now = new Date();
            let diffDates = (new Date(getCookie('auth-expiration'))).getTime() - (new Date()).getTime();
            if (diffDates <= 0) {
                this.logout();
            } else {
                diffDates /= 1000;
                diffDates /= 60;
                diffDates = Math.abs(Math.round(diffDates));

                if (diffDates <= 30)
                    this.refreshToken()
            }
            return true;
        }

        return false;
    }

}

export default UserService;