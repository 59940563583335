import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router'
import Page_404 from '../views/Page_404.vue'
import {availableLang, defaultLang, getLangConfig, setLangConfig} from "@/i18n";
import enRoutes from "@/router/enRoutes";
import ukRoutes from "@/router/ukRoutes";
import deRoutes from "@/router/deRoutes";
import ruRoutes from "@/router/ruRoutes";
import plRoutes from "@/router/plRoutes";
import {Role} from "@/helpers/roleHelper";
import UserService from "@/services/userService";
import {useAuthStore} from "@/stores/authStore";


export function getRouteName(name: string) {
    if (getLangConfig() == defaultLang())
        return name;
    else
        return getLangConfig() + '.' + name;
}

let allRoutes: Array<RouteRecordRaw> = [];
allRoutes = allRoutes.concat(
    plRoutes,
    enRoutes,
    ukRoutes,
    deRoutes,
    ruRoutes,
    [
        {
            path: '/:pathMatch(.*)*',
            name: 'notFound',
            component: Page_404
        }
    ]
)

const routes = allRoutes;

const router = createRouter({
    history: createWebHistory(),
    routes
})

const authorizedRoutes = {
    'inquiries': [Role.Client, Role.Translator, Role.TranslatorVIP],
    'orders': [Role.Client, Role.Translator, Role.TranslatorVIP],
    'invoices': [Role.TranslatorVIP],
    'profileSettings': [Role.Translator, Role.TranslatorVIP],
    'accountSettings': [Role.Client, Role.Translator, Role.TranslatorVIP],
};

const forbiddenForAuthorizedRoutes = ['login', 'registration', 'registrationEmailVerification', 'registrationTranslatorVerification', 'forgotPassword', 'changePassword']

router.beforeEach(async (to, from, next) => {

    const metaTag = document.querySelector('meta[name=description]');
    if(to.meta.description)
        metaTag?.setAttribute('content', String(to.meta.description));

    document.title = to.meta.title ? String(to.meta.title): 'SwornApp';

    let basicRouteName = '';
    if (to.meta.defaultLang) {
        if (getLangConfig() != defaultLang())
            setLangConfig(defaultLang(), false);
        basicRouteName = (to.name ?? '').toString();
    } else {
        let routeName = to.name ?? '';
        routeName = routeName.toString();
        const routeNameParts = routeName.split('.');

        for (let i = 1; i < routeNameParts.length; i++) {
            basicRouteName += routeNameParts[i];
        }

        if (getLangConfig() != routeNameParts[0]) {
            if (Object.prototype.hasOwnProperty.call(availableLang, routeNameParts[0]))
                setLangConfig(routeNameParts[0], false);
            else
                setLangConfig(defaultLang(), false);
        }
    }
    const userService = new UserService();
    if (basicRouteName in authorizedRoutes) {
        if (!userService.checkToken()) {
            next({
                name: getRouteName('login')
            })
        } else {
            const auth = useAuthStore();
            if (await auth.getUser()){
                if (auth.user?.userPermissions) {
                    for (let i = 0; i < auth.user?.userPermissions?.length; i++) {
                        if (authorizedRoutes[basicRouteName].includes(auth.user?.userPermissions[i]))
                            next()
                    }
                }

                next({
                    name: getRouteName('notFound')
                })

            } else {
                const userService = new UserService();
                userService.logout();
                next({name: getRouteName('login')})
            }
        }
    } else if (forbiddenForAuthorizedRoutes.includes(basicRouteName)) {
        if (userService.checkToken()) {
            next({name: getRouteName('inquiries')})
        } else {
            next();
        }
    } else {
        if (!userService.checkToken()) {
            next()
        } else {
            const auth = useAuthStore();
            if (await auth.getUser()) {
                next()
            }
        }
    }

});

export default router
